<template>
    <div class="documents">
        <div class="form-container">
            <h1>{{PageTitle}}</h1>
            <div :class="['documents-container']">
                <div v-if="!!actual" class="documents-cards">
                    <rwm-document-card
                            v-for="card in actual"
                            :key="card.id"
                            :download-link="card.scan ? card.scan : true"
                            :title="card.name"
                            :is-active="!!actual"
                            :action="card.action"
                            :status="card.status"
                            :admin-comment="card.comment_reject"
                            :messages="card.alerts"
                            :application="card.application"
                            :personalAccount="card.personalAccount"
                            :paOpeningDate="card.paOpeningDate"
                            :sing-date="date(card.date_sign)"
                            :spec-number="card.fond ? card.fond.specreg.name : null"
                            :fund-name="card.fond ? card.fond.name : null"
                            :notice-docs="card.files.find(el => el.file_type === 302) ? card.files.find(el => el.file_type === 302) : ''"
                            :note-ki-docs="card.files.find(el => el.file_type === 300) ? card.files.find(el => el.file_type === 300) : ''"
                            @download="toDownload($event||card)"
                    />
                </div>
                <!--                color RWM/Grey-->
                <div class="row" v-if="showButton">
                    <div v-if="!(!!actual)" class="col-12">
                        <p class="anketa">У вас нет отправленных документов{{hasDraft ? " ,подтвердите, пожалуйста, данные указанные в анкете:" : "."}}</p>
                    </div>
                    <div class="col-3">
                        <rwm-button v-if="showButton" outlined width="100%" @click="$emit('fillOut')">{{hasDraft ? "Подтвердить данные" : "Заполнить анкету"}}</rwm-button>
                    </div>
                </div>
                <rwm-info
                        v-if="infoText"
                        :msg="infoText"
                        icon="warning"
                        color="#DA291C"
                />
                <div :class="['documents-info']" v-if="infoBlock">
                    <div class="anketa" v-html="infoBlock" />
                </div>

                <div v-if="cards && cards.length > 0" class="documents-cards">
                    <rwm-document-card
                            v-for="card in cards"
                            :key="card.id"
                            :download-link="card.scan ? card.scan : true"
                            :title="card.title"
                            :is-active="false"
                            :sing-date="date(card.date_sign)"
                            :status="card.status"
                            :notification="card.notification"
                            :application="card.application"
                            :personalAccount="card.personalAccount"
                            :paOpeningDate="card.paOpeningDate"
                            @download="toDownload(card)"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import RwmDocumentCard from "../../../RWMDocumentCard/RwmDocumentCadr";
    import RwmButton from "../../../RWMButton/RwmButton";
    import RwmInfo from "../../../RWMInfo/RwmInfo";
    import dateFormatter from "../../../../modules/module.date";
    export default {
        name: "KI",
        components: {RwmInfo, RwmButton, RwmDocumentCard},
        props: {
            PageTitle: String,
            infoBlock: String,
            cardTitle: String,
            infoText: String,
            cards: Array,
            hasDraft: Boolean,
            actual: Array,
            showButton: {
                type: Boolean,
                default: true
            }
        },
        methods: {
            date(date) {
                return dateFormatter(date)
            },
          toDownload(card) {
            if (card.scan) {
              const name = card.scan.text_name ? card.scan.text_name + card.origin_name.split(".")[1] : card.scan.origin_name
              this.$emit('download-scan', {name: name, id: card.scan.id})
            } else if (card.file) {
                const name = card.text_name ? card.text_name + card.origin_name.split(".")[1] : card.origin_name
                this.$emit('download-scan', {name: name, id: card.id})
            } else {
              this.$emit('download', card.id)
            }
          }
        }
    }
</script>

<style scoped lang="scss">
    .documents {
        &-container {
            display: flex;
            flex-direction: column;
            row-gap: 24px;
            & .documents-cards {
                display: flex;
                column-gap: 24px;
                row-gap: 24px;
                flex-wrap: wrap;
                @media screen and (min-width: 1600px) {
                    min-width: 718px;
                }
            }
        }
    }
</style>
<style lang="scss">
    .anketa {
        & ul {
            & li {
                margin-bottom: 24px;
            }
        }
    }
</style>